import { Injectable } from '@angular/core';
import { Connection } from '@app/models/config.model';
import { AuthService } from '@rockwell-automation-inc/service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private readonly authService: AuthService) {}

  isAuthenticated$ = this.authService.isAuthenticated$;
  user$ = this.authService.user$;

  loginWithTestUsers(targetPath: string): void {
    this.authService.login(targetPath);
  }

  login(connection: Connection, targetPath: string): void {
    this.authService.loginSSO(connection, targetPath);
  }

  logout(): void {
    this.authService.logout();
  }

  checkAuth0Session(): Promise<boolean> {
    return this.authService.checkAuth0Session();
  }
}
