import { createAction, props } from '@ngrx/store';
import { AllocateTrial, RedeemTrial, ReservationInfo, TrialBundle, TrialReservation } from '../lemans-app.model';

export const getTrialBundles = createAction('[TRIALS] Get trial bundles');

export const setTrialBundles = createAction('[Trials] Set trial bundles', props<{ payload: TrialBundle[] }>());

export const getTrialReservationInfo = createAction(
  '[TRIALS] Get trial reservation information',
  props<{ payload: ReservationInfo }>(),
);

export const setTrialReservationInfo = createAction(
  '[TRIALS] Set trial reservation information',
  props<{ payload: TrialReservation }>(),
);
export const redeemTrial = createAction('[TRIALS] Redeem trial', props<{ payload: RedeemTrial }>());
export const redeemTrialSuccess = createAction('[TRIALS] Redeem trial success', props<{ payload: RedeemTrial }>());
export const allocateTrial = createAction('[TRIALS] Allocate trial', props<{ payload: AllocateTrial }>());
export const updateTrials = createAction('[TRIALS] update trials data', props<{ value: boolean }>());

export const scheduleDelayedAction = createAction('[TRIALS] Delayed Action', props<{ value: number }>());

