import {
  AgFilterContext,
  AgFilterModel,
  AgGridEntitlementColumnsFormat,
  BadgeInfo,
  Entitlement,
  EntitlementBadge,
  EntitlementStatus,
  EntitlmentService,
  EntitylementTypes,
  FilterContext,
  FilterModel,
  FilterType,
  ITenantDetail,
  InvitationsStatus,
  ManageResourceAccess,
  RASymbols,
  RemoveResourceAccess,
  ResourceTypeString,
  SnackBarType,
  TOKEN_BALANCE_FRACTION_DIGITS,
  TenantStatus,
  defaultLanguage,
  displayMessage,
  navigateToError,
  setTenantStatus,
} from '@ra-state';
import { DialogService, IDialogConfig } from '@ra-web-tech-ui-toolkit/components';
import { DateTime, Duration } from 'luxon';
import { Observable, from } from 'rxjs';
import * as _ from 'lodash';
import { AppId } from '@rockwell-automation-inc/common-utils';
import { APP_BOOTSTRAP_LISTENER, Inject, InjectionToken, Type } from '@angular/core';
import { EffectSources } from '@ngrx/effects';
import { ENV_VARS } from '@app/models/config.model';
import { HttpStatusCode } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ApiError } from '@core/api-error';
import { AppErrorCodes } from '@core/common-constants';

export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

export function bootstrapEffects(effects: Type<any>[], sources$: EffectSources) {
  return (): void => {
    effects.forEach((effect) => sources$.addEffects(effect));
  };
}

export function createInstances(...instances: any[]): any[] {
  return instances;
}

export function provideBootstrapEffects(effects: Type<any>[]): any[] {
  return [
    effects,
    { deps: effects, provide: BOOTSTRAP_EFFECTS, useFactory: createInstances },
    {
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources],
      multi: true,
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: bootstrapEffects,
    },
  ];
}

export function getEnv(key: ENV_VARS): any {
  if (!(key in window) || typeof window[key] === 'undefined') {
    throw new Error(`Env var not defined ${key}`);
  }
  const value = window[key];
  if (typeof value === 'string' && value === '') {
    throw new Error(`Env var is defined ${key} with value ''`);
  }

  return window[key];
}

export function getEnvOrDefault(key: ENV_VARS, defaultVal): any {
  try {
    return getEnv(key);
  } catch (e) {
    return defaultVal;
  }
}

export function getBadgeInfo(value: string): BadgeInfo {
  switch (value) {
    case EntitylementTypes.addOns:
      return { color: 'lightBlue', text: 'Add-Ons' };
    case EntitylementTypes.trial:
      return { color: 'darkBlue', text: EntitylementTypes.trial };
    case EntitylementTypes.platform:
      return { color: 'blue', text: EntitylementTypes.platform };
    case EntitylementTypes.additive:
      return { color: 'purple', text: EntitylementTypes.additive };
    case EntitylementTypes.utilityToken:
      return { color: 'purple', text: 'Universal Credits' };
    default:
      return { color: 'none', text: RASymbols.MINUS };
  }
}

export function formatEntitlementCopyColumnContent(data: AgGridEntitlementColumnsFormat): any {
  return `${data.header} ${data.subheader}`;
}

export function mapServiceData(params: Entitlement): any {
  // TODO: this func is extremely fishy - see this.allApps below - it's coming from the default this from
  const serviceKind = params?.serviceKind;
  const appName = serviceKind ? getAppNameOrDefault(this.allApps, serviceKind) : '';
  return {
    header: this.catalogInfos.get(params?.catalogNumber)?.name || params?.catalogNumber,
    subheader: appName + 'Catalog: ' + params?.catalogNumber,
  };
}

function getAppNameOrDefault(allApps, serviceKind): any {
  return (allApps.get(serviceKind)?.appName || serviceKind) + ' | ';
}

export function dateIsValid(date): any {
  return !Number.isNaN(new Date(date).getTime());
}

export function formatBadgeCopyColumnContent(badge: string): any {
  return EntitlementBadge[badge as unknown as keyof typeof EntitlementBadge];
}

export function openDialogWrapper$(dialogSvc: DialogService, config: IDialogConfig): Observable<any> {
  const dialog = dialogSvc.openDialog(config);
  return dialog.componentInstance.onClick;
}

export function getDisplayResourceType(v: ResourceTypeString): string {
  if (v === 'Tenant') {
    return 'Organization';
  }
  return v;
}

export function agDateComparator(filterDate: Date, cellValue: string | null): any {
  if (cellValue === null) {
    return -1;
  }
  const cellDate = new Date(cellValue);
  cellDate.setHours(0, 0, 0, 0); // Setting hours as 0 for exact comparision - As filterDate is filterLocalDateAtMidnight (without hours)
  if (filterDate.getTime() === cellDate.getTime()) {
    return 0;
  }
  if (cellDate.getTime() <= filterDate.getTime()) {
    return -1;
  }
  if (cellDate.getTime() >= filterDate.getTime()) {
    return 1;
  }
  return 0;
}

export function toFilterModel(agFilterModel: AgFilterModel): FilterModel {
  const filterModel: FilterModel = {};
  Object.keys(agFilterModel).forEach((key) => {
    const agFilterContext: AgFilterContext = agFilterModel[key];
    const filterContext: FilterContext = {
      type: agFilterModel[key].type,
      filterType: agFilterModel[key].filterType,
    };

    switch (agFilterContext.filterType) {
      case 'number':
      case 'text':
        filterContext.filter = agFilterModel[key].filter;
        filterContext.filterTo = agFilterModel[key].filterTo;
        break;

      case 'set':
        filterContext.values = agFilterModel[key].values?.map((value) => {
          if (value === null) {
            return '';
          }
          return value;
        });
        break;

      case 'date':
        filterContext.dateFrom = getDateWithMaxOrMinDayTime(`${agFilterContext?.dateFrom}`, agFilterContext?.type);
        filterContext.dateTo = agFilterContext.dateTo
          ? getDateWithMaxDayTime(`${agFilterContext?.dateTo}`)
          : new Date();
        break;

      default:
        break;
    }
    filterModel[key] = filterContext;
  });
  return filterModel;
}

export function getDateWithMaxOrMinDayTime(value: string, filterType?: FilterType): Date {
  switch (filterType) {
    case 'lessThanOrEqual':
      return getDateWithMaxDayTime(value);
    case 'greaterThanOrEqual':
      return getDateWithMinDayTime(value);
    case 'inRange':
      return getDateWithMinDayTime(value);
    default: {
      const datePart = value.split(' ')[0];
      return new Date(datePart);
    }
  }
}

export function getDateWithMaxDayTime(value: string): Date {
  const datePart = value.split(' ')[0];
  return new Date(`${datePart}T23:59:59`);
}

export function getDateWithMinDayTime(value: string): Date {
  const datePart = value.split(' ')[0];
  return new Date(`${datePart}T00:00:01`);
}

export class Awaiter {
  promises: Map<Promise<boolean>, any> = new Map();
  createPromise(): Promise<boolean> {
    let deferred: any;
    const p = new Promise<boolean>((resolve, reject) => (deferred = { resolve, reject }));
    this.promises.set(p, deferred);
    return p;
  }
  resolve(p: Promise<boolean>): void {
    this.promises.get(p).resolve();
  }
  reject(p: Promise<boolean>): void {
    this.promises.get(p).reject();
  }
  whenAll(): Promise<any> {
    const promises = [...this.promises.keys()];
    if (promises.length === 0) {
      return new Promise<void>((resolve, _reject) => resolve());
    }
    return Promise.all(promises);
  }

  whenAnySettles(): Promise<any> {
    const promises = [...this.promises.keys()];
    if (promises.length === 0) {
      return new Promise<void>((resolve, _reject) => resolve());
    }
    return Promise.race(promises);
  }
  whenAllSettled(): Promise<any> {
    return Promise.allSettled([...this.promises.keys()]);
  }
  whenFirstSuccess(): Promise<any> {
    return Promise.any([...this.promises.keys()]);
  }
  any = this.whenFirstSuccess;
  race = this.whenAnySettles;
  allSettled = this.whenAllSettled;
  get all$(): Observable<any> {
    return from(this.whenAll());
  }
  get any$(): Observable<any> {
    return from(this.any());
  }
}

export function openURL(url: string): void {
  window.open(url, '_blank');
}

export function roundToThreeDecimals(value: number): number {
  const roundingFactor = Math.pow(10, TOKEN_BALANCE_FRACTION_DIGITS);
  return Math.ceil(value * roundingFactor) / roundingFactor;
}

export function getEntitlementStatus(status: EntitlementStatus): string {
  return status === EntitlementStatus.COMPLETED ? 'Expired' : status;
}

export function modifyFilterValuesforCredit(filterModel: FilterModel, colName: string): FilterModel {
  const filterModelClone = _.cloneDeep(filterModel);
  if (filterModelClone[colName]) {
    filterModelClone['hasCredits'] = {
      ...filterModelClone[colName],
      values: filterModelClone[colName].values?.map((value) => (value === 'with-credits' ? 'True' : 'False')),
    };
    delete filterModelClone[colName];
  }
  return filterModelClone;
}

export function getInvitationStatus(status: InvitationsStatus): string {
  let statusText: string = status;
  if (status === InvitationsStatus.ACCEPTEDALERT) {
    statusText = 'Accepted by different email';
  } else if (status === InvitationsStatus.ACTIVE) {
    statusText = 'Sent';
  }
  return statusText;
}

export function checkIfTrialEntitlement(entitlement: Entitlement): boolean {
  if (entitlement.attributes?.campaignId || entitlement.catalogNumber === 'TRIAL-CAMPAIGN-CREDITS') {
    return true;
  }
  return false;
}

export function getServiceEntitlements(tenantDetails: ITenantDetail): Entitlement[] {
  const mapEntitlements = (services: EntitlmentService[]): Entitlement[] => {
    return (
      services
        ?.flatMap((service) => {
          return service.entitlements.map((entitlement) => ({
            ...entitlement,
            serviceKind: service.kind,
            isTrialEntitlement: checkIfTrialEntitlement(entitlement),
          }));
        })
        ?.filter((entitlement) => !entitlement.isSystemGenerated) || []
    );
  };

  const pendingServicesEntitlements = (tenantDetails.pendingServices || [])
    .flatMap((pendingService) => {
      return pendingService.entitlement
        ? {
            ...pendingService.entitlement,
            serviceKind: pendingService.kind,
            isTrialEntitlement: checkIfTrialEntitlement(pendingService.entitlement),
          }
        : [];
    })
    .filter((entitlement) => !entitlement.isSystemGenerated);

  const provisionedServicesEntitlements = mapEntitlements(tenantDetails.services || []);
  const disabledServiceEntitlements = mapEntitlements(tenantDetails.disabledServices || []);

  return [...provisionedServicesEntitlements, ...pendingServicesEntitlements, ...disabledServiceEntitlements];
}

export function isRemoveAccessResource(row: ManageResourceAccess): boolean {
  return (row as RemoveResourceAccess)?.availableToDelete !== undefined;
}

export function getTimeLeft(endDate: string): string {
  const expiryDate = DateTime.fromISO(endDate);
  const currentDate = DateTime.now();
  const diff = expiryDate.diff(currentDate, ['days']);
  let expirationTime = diff.toObject();
  if (expirationTime.days && expirationTime.days <= 1) {
    expirationTime = expiryDate.diff(currentDate, ['hours', 'minutes']).toObject();
    expirationTime = { ...expirationTime, minutes: expirationTime.minutes ? Math.floor(expirationTime.minutes) : 0 };
  } else {
    expirationTime = { ...expirationTime, days: expirationTime.days ? Math.floor(expirationTime.days) : 0 };
  }
  return Duration.fromObject(expirationTime, { locale: defaultLanguage }).toHuman({ unitDisplay: 'short' });
}

export function toAppId(appId: string): AppId {
  switch (appId.toLowerCase()) {
    case 'arena':
      return AppId.Arena;
    case 'assetcenter':
      return AppId.AssetCenter;
    case 'batchperformanceanalytics':
      return AppId.BatchPerformanceAnalytics;
    case 'cds':
      return AppId.CDS;
    case 'datamosaix':
      return AppId.DataMosaix;
    case 'digitalengineering':
      return AppId.DigitalEngineering;
    case 'digitaltechtransfer':
      return AppId.DigitalTechTransfer;
    case 'edm':
      return AppId.EDM;
    case 'elementary':
      return AppId.Elementary;
    case 'emulate3d':
      return AppId.Emulate3D;
    case 'fooservice':
      return AppId.FooService;
    default:
      throw new Error('Unknown appId/servicekind from backend');
  }
}

export const notFoundError = "We couldn't find what you are looking for. Please check the URL and try again.";
export const forbiddenError = 'Insufficient Permissions. Please contact organization admin to perform this operation';
export const badRequestError = 'The request was invalid.';
export const conflictError = 'The requested change conflicts with the current state. Refresh the page and try again.';
export const alreadyGoneError = 'The resource has been deleted. Please refresh';
export const tooManyRequestsError = (retryAfterSeconds: number): string => {
  return `Please retry again after ${retryAfterSeconds} seconds.`;
};
export const gatewayTimeout = 'Operation Timeout. Please refresh.';
export const internalServerError = "We're experiencing technical difficulties. Please try again in a few moments";

export const tenantArchivedReadOnlyError = 'An administrator may have archived or modified the organization.';

export const defaultErrorHandlers: Partial<Record<HttpStatusCode, (apiError: ApiError, _body: any) => Action[]>> = {
  [HttpStatusCode.NotFound]: displaySnackBarMessage(notFoundError),
  [HttpStatusCode.Forbidden]: displaySnackBarMessage(forbiddenError),
  [HttpStatusCode.BadRequest]: displaySnackBarMessage(badRequestError),
  [HttpStatusCode.Conflict]: (apiError: ApiError, _body: any): Action[] => {
    if (apiError.ErrorCode === AppErrorCodes.TenantArchivedReadOnly) {
      const actions = displaySnackBarMessage(tenantArchivedReadOnlyError)(apiError, _body);
      actions.push(
        setTenantStatus({
          tenantStatus: TenantStatus.Archived,
        }),
      );
      return actions;
    }
    return displaySnackBarMessage(conflictError)(apiError, _body);
  },
  [HttpStatusCode.Gone]: displaySnackBarMessage(alreadyGoneError),
  [HttpStatusCode.TooManyRequests]: (apiError: ApiError, _body: any): Action[] => {
    const retryAfterSeconds = DateTime.fromISO(apiError.RetryAfter).second;
    return displaySnackBarMessage(tooManyRequestsError(retryAfterSeconds))(apiError, _body);
  },
  [HttpStatusCode.GatewayTimeout]: displaySnackBarMessage(gatewayTimeout),
  [HttpStatusCode.InternalServerError]: (apiError: ApiError, _body: any): Action[] => {
    const actions = displaySnackBarMessage(internalServerError)(apiError, _body);
    actions.push(navigateToError({ payload: apiError }));
    return actions;
  },
};

export function displaySnackBarMessage(message: string, type?: SnackBarType) {
  return (_apiError: ApiError, _body: any): Action[] => [
    displayMessage({
      payload: {
        message: message,
        type: type || 'Error',
      },
    }),
  ];
}
