import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { GainsightService } from '@servicesV2/gainsight.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  ApplicationCardAction,
  IGainsightCustomEventName,
  IGainsightOrganizationContext,
  IGainsightUserIdentity,
  LinkStatus,
  productToString,
} from '../lemans-app.model';
import { resetSession, pushCustomEvent } from './gainsight.actions';
import {
  UserDataFacade,
  applyCustomProvisioningEntitlement,
  applyRegularEntitlement,
  cardClicked,
  setTenantDetails,
  getUserPreferencesSuccess,
} from '../user-data';
import { userLoginComplete } from '../control-page/control-page.actions';
import { CommonConstants } from '@core/common-constants';
import { DataService } from '@servicesV2/data.service';
import { redeemTrialSuccess, TrialsFacade } from '@app/+state';

@Injectable({
  providedIn: 'root',
})
export class GainsightEffects {
  pushUserProfile$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(userLoginComplete),
        map((payload) => {
          const userProfile = payload.payload;
          const userIdentity: IGainsightUserIdentity = {
            email: userProfile.email,
            id: userProfile.userId,
            domain: CommonConstants.getEmailDomain(userProfile.email),
            mfa: userProfile.customClaims?.mfa_otp === 'true',
            raBusinessPartnerID: CommonConstants.cleanLeftTrailingZeros(userProfile.raBusinessPartnerID),
            company: userProfile.company,
          };
          this.gainsightService.pushUserProfile(userIdentity);
        }),
      );
    },
    { dispatch: false },
  );

  resetSession$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(resetSession),
        map(() => {
          this.gainsightService.reset();
        }),
      );
    },
    { dispatch: false },
  );

  setOrganizationContext$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(setTenantDetails),
        map((payload) => {
          const context: IGainsightOrganizationContext = {
            organizationId: String(payload.payload?.id),
            organizationName: String(payload.payload?.name),
          };
          this.gainsightService.setGlobalContext(context);
        }),
      );
    },
    { dispatch: false },
  );

  pushCustomEvent$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(pushCustomEvent),
        map((payload) => {
          this.gainsightService.pushCustomEvent(payload.gainsightCustomEvent);
        }),
      );
    },
    { dispatch: false },
  );

  pushEntitlementAppliedEvent$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(applyRegularEntitlement, applyCustomProvisioningEntitlement),
        map((payload) => {
          const { domain, quantity } = payload;
          const { available, catalogNumber, name, serviceKind, status } = payload.entitlementData.entitlement;
          const customEvent = {
            eventName: IGainsightCustomEventName.EntitlementApplied,
            eventPayload: { available, catalogNumber, name, serviceKind, status, domain, quantity },
          };
          this.gainsightService.pushCustomEvent(customEvent);
        }),
      );
    },
    { dispatch: false },
  );

  pushUsersActiveEntitlements$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(getUserPreferencesSuccess),
        concatLatestFrom(() => this.userDataFacade.userID$),
        switchMap(([_payload, userId]) => {
          return this.dataService.getUsersActiveEntitlements$(String(userId));
        }),
        map((unallocatedEntitlements) => {
          const customEvent = {
            eventName: IGainsightCustomEventName.PostLoginUnallocatedEntitlements,
            eventPayload: {
              count: unallocatedEntitlements.total,
            },
          };
          this.gainsightService.pushCustomEvent(customEvent);
        }),
      );
    },
    { dispatch: false },
  );

  cardClicked$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(cardClicked),
        map((payload) => {
          switch (payload.action) {
            case ApplicationCardAction.LOGO:
              this.gainsightService.pushCustomEvent({
                eventName: IGainsightCustomEventName.ApplicationCard,
                eventPayload: { applicationId: payload.card.appName, applicationName: payload.card.appId },
              });
              break;
            case ApplicationCardAction.INFO:
              this.gainsightService.pushCustomEvent({
                eventName: IGainsightCustomEventName.ApplicationCardInfo,
                eventPayload: { applicationId: payload.card.appName, applicationName: payload.card.appId },
              });
              break;
            default:
              break;
          }
        }),
      );
    },
    { dispatch: false },
  );

  trialRedeemed$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(redeemTrialSuccess),
        concatLatestFrom(() => this.trialsFacade.trialBundles$),
        map(([trialRedeemed, trialBundles]) => {
          const bundleId = trialRedeemed.payload.trialReservationDetails.campaignBundleId;
          const [trialBundle] = trialBundles.filter((el) => el.id === bundleId);
          const customEvent = {
            eventName: IGainsightCustomEventName.BundleTrialRedeemed,
            eventPayload: {
              trialReservationId: trialRedeemed.payload.trialReservationId,
              id: trialRedeemed.payload.trialReservationDetails.id,
              campaignId: trialRedeemed.payload.trialReservationDetails.campaignId,
              status: LinkStatus.REDEEMED,
              campaignBundleId: trialRedeemed.payload.trialReservationDetails.campaignBundleId,
              durationInDays: trialRedeemed.payload.trialReservationDetails.durationInDays,
              expiresOn: trialRedeemed.payload.trialReservationDetails.expiresOn,
              trialBundleName: trialBundle.name,
              credits: trialBundle.credits ? 'With credits' : 'Without credits',
              products: trialBundle.products.map(productToString).join(', '),
            },
          };
          this.gainsightService.pushCustomEvent(customEvent);
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private gainsightService: GainsightService,
    private dataService: DataService,
    private userDataFacade: UserDataFacade,
    private trialsFacade: TrialsFacade,
  ) {}
}
