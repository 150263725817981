import { Action, createReducer, on } from '@ngrx/store';
import { clearAgGridRefresh } from './ag-grid.action';

import { AgGridState } from '../lemans-app.model';
import {
  approveUserSuccess,
  createInvitationsSuccess,
  createSelectedFakeEntitlementsSuccess,
  deleteInvitationSuccess,
  dismissUserSuccess,
  removeAccessSuccess,
  resendInvitationSuccess,
  updateEntitlements,
} from '../user-data';
import { updateTrials } from '../trials';

export const agInitialState: AgGridState = {
  refreshAgGrid: undefined,
};

const agGridActions = createReducer(
  agInitialState,
  on(
    clearAgGridRefresh,
    (state: AgGridState): AgGridState => ({
      ...state,
      refreshAgGrid: undefined,
    }),
  ),
  on(
    removeAccessSuccess,
    resendInvitationSuccess,
    deleteInvitationSuccess,
    createSelectedFakeEntitlementsSuccess,
    approveUserSuccess,
    dismissUserSuccess,
    updateEntitlements,
    updateTrials,
    createInvitationsSuccess,
    (state: AgGridState, { value }): AgGridState => ({
      ...state,
      refreshAgGrid: value,
    }),
  ),
);

export function agGridReducer(state: AgGridState, action: Action): AgGridState {
  return agGridActions(state, action);
}
