import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { EMPTY, mergeMap, Observable, of, tap } from 'rxjs';
import { ControlPageFacade, ITenantDetail, Role, SnackBarFacade, UserDataFacade } from '@ra-state';
import { HttpMethod } from '@auth0/auth0-angular';
import { Router } from '@angular/router';

@Injectable()
export class TenantStatusInterceptor implements HttpInterceptor, OnDestroy {
  currentTenant: ITenantDetail | undefined;
  tenantAdminOrOwner: boolean | undefined;
  // styling breaks when we place this obervavble inside the intercept function
  getCurrentTenantSub$ = this.userDataFacade.currentTenant$
    .pipe(tap((currentTenant) => (this.currentTenant = currentTenant)))
    .subscribe();

  getEffectiveRolesSub$ = this.userDataFacade.currentTenantContext$
    .pipe(
      tap((currentTenantContext) => {
        if (currentTenantContext) {
          this.tenantAdminOrOwner = [Role.Owner, Role.Admin].includes(currentTenantContext.role)
        }
      }),
    )
    .subscribe();
  constructor(
    private readonly router: Router,
    private readonly userDataFacade: UserDataFacade,
    private readonly controlPageFacade: ControlPageFacade,
    private readonly snackBarFacade: SnackBarFacade
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(null).pipe(
      mergeMap(() => {
        const currentUrl = this.router.url.toLowerCase().split('#')[0];
        if (
          this.tenantAdminOrOwner &&
          !['/organization/edit'].includes(currentUrl) &&
          this.currentTenant &&
          this.currentTenant.tenantStatus === 'Archived' &&
          request.method !== HttpMethod.Get
        ) {
          this.controlPageFacade.setLoading(false);
          this.snackBarFacade.displayMessage({
            message: "This organization is currently archived.",
            type: 'Info',
          });
          return EMPTY;
        }
        return next.handle(request);
      }),
    );
  }

  ngOnDestroy(): void { 
    this.getCurrentTenantSub$.unsubscribe();
    this.getEffectiveRolesSub$.unsubscribe();
  }

}
