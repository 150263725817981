import { Injectable } from '@angular/core';
import {
  ApplicationCard,
  ControlPageFacade,
  CreateInvitation,
  PreviewAppId,
  displayMessage,
  navigateTo,
  openWebTechDialog,
  setInviteError,
} from '@ra-state';
import { tap } from 'rxjs';
import { AppId } from '@rockwell-automation-inc/common-utils';
import { ApiError } from '@core/api-error';
import { Action } from '@ngrx/store';
import { ApiEndPoints } from './data.service';
import { AppErrorCodes } from '@core/common-constants';
import { toAppId } from '@app/v2/shared/utils';
import { ActionButtonStyles, NotificationType } from '@ra-web-tech-ui-toolkit/components';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlers {
  appsMap: Map<AppId | PreviewAppId, ApplicationCard>;

  appsMap$ = this.controlPageFacade.getAppsMap$
    .pipe(
      tap((appsMap) => {
        this.appsMap = appsMap;
      }),
    )
    .subscribe();

  handleEntitlementErrors = (apiError: ApiError, _body: any): Action[] => {
    if (!apiError.Url.includes(ApiEndPoints.Users) || !apiError.Url.includes('/entitlements/')) {
      throw new Error('Url mismatch: API url does not match entitlements endpoint');
    }

    switch (apiError.ErrorCode) {
      case AppErrorCodes.TenantServiceProvisioningPending: {
        const appId = toAppId(apiError.ErrorCodeParts[1]);
        const errorMsg = `${this.appsMap.get(appId)?.appName} needs further action to be enabled for your organization. Please open to complete.`;
        return [
          displayMessage({
            payload: {
              message: errorMsg,
              type: 'Info',
              customBtn: {
                label: 'Go to Home',
                navigateTo: '/dashboard',
              },
            },
          }),
        ];
      }

      case AppErrorCodes.EntitlementAlreadyConsumed:
        return [this.displayMessage('Entitlement already consumed.')];

      case AppErrorCodes.EntitlementTransactionInProgress:
        return [
          this.displayMessage(
            'Service provisioning is in progress, please complete the provisioning before applying the entitlement.',
          ),
        ];

      case AppErrorCodes.InactiveEntitlement:
        return [this.displayMessage('Cannot allocate, the entitlement is no more active.')];
      case AppErrorCodes.PlatformEntitlementsCannotOverlap:
        return [this.displayMessage('Service already has a platform entitlement allocated for the time period.')];

      case AppErrorCodes.CannotApplyEntitlementToPersonalTenant:
        return [this.displayMessage('Entitlements cannot be allocated to a personal organization.')];

      case AppErrorCodes.EntitlementNotActive:
        return [this.displayMessage('Entitlement is not active.')];

      case AppErrorCodes.AddOnBaseEntitlementPrerequisiteNotMet:
        return [
          this.displayMessage(
            'Platform/Additive/Trial entitlement needs to applied before applying an Add-on entitlement.',
          ),
        ];

      case AppErrorCodes.EntitlementDoesNotSupportMoreThanOneQuantity:
        return [this.displayMessage('Entitlement does not support more that one quantity to be allocated at a time')];

      default:
        return this.defaultHandler();
    }
  };

  handleInvitationErrors = (apiError: ApiError, body: any): Action[] => {
    if (!apiError.Url.includes(ApiEndPoints.Invitations)) {
      throw new Error('Url mismatch: API url does not match invitations endpoint');
    }
    const createInvPayload = body as CreateInvitation;
    switch (apiError.ErrorCode) {
      case AppErrorCodes.InvitationAlreadyExists:
        return [setInviteError({ payload: { email: createInvPayload.toEmail, error: apiError } })];

      case AppErrorCodes.InvitationAlreadyAccepted:
        return [this.displayMessage('Invitation is already accepted.')];

      case AppErrorCodes.InvitationAlreadyCancelled:
        return [this.displayMessage('Invitation is cancelled. Please contact your administrator.')];

      case AppErrorCodes.InvitationExpired:
        return [this.displayMessage('Invitation is expired. Please contact your administrator.')];

      case AppErrorCodes.InvitationNotActive:
        return [this.displayMessage('Invitation is not active. Please contact your administrator.')];

      case AppErrorCodes.UserAlreadyHasAccessToSpecifiedResource:
        return [setInviteError({ payload: { email: createInvPayload.toEmail, error: apiError } })];

      default:
        return this.defaultHandler();
    }
  };

  displayMessage(message: string): Action {
    return displayMessage({
      payload: {
        message: message,
        type: 'Error',
      },
    });
  }

  defaultHandler(): Action[] {
    return [
      displayMessage({
        payload: {
          message: 'Something went wrong',
          type: 'Error',
          customBtn: {
            label: 'Go to Home',
            navigateTo: '/dashboard',
          },
        },
      }),
    ];
  }

  handleAccessRequestErrors = (apiError: ApiError, _body: any): Action[] => {
    if (!apiError.Url.includes(ApiEndPoints.AccessRequest)) {
      throw new Error('Url mismatch: API url does not match invitations endpoint');
    }
    if (apiError.ErrorCode === AppErrorCodes.UserAlreadyHasAccessToSpecifiedResource) {
      return [this.displayMessage('Your account already has access to the organization.')];
    } else {
      return this.defaultHandler();
    }
  };

  handleTrialErrors = (apiError: ApiError, _body: any): Action[] => {
    if (!apiError.Url.includes(ApiEndPoints.Trial)) {
      throw new Error('Url mismatch: API url does not match trial endpoint');
    }

    switch (apiError.ErrorCode) {
      case AppErrorCodes.TrialAlreadyRedeemed: {
        const config = {
          title: 'Trial already redeemed',
          message:
            'This trial link has already been used. Please check your account trials. If you still have questions, reach out to your sales representative.',
          buttons: [{ label: 'Close', buttonStyle: ActionButtonStyles.Main }],
          showCloseIconButton: true,
        };

        return [
          openWebTechDialog({ config: config, notificationType: NotificationType.Error }),
          navigateTo({ path: '/dashboard' }),
        ];
      }
      default: {
        const config2 = {
          title: 'Invalid Trial Link',
          message: 'Please contact your sales representative for a new link. We apologize for the inconvenience.',
          buttons: [{ label: 'Close', buttonStyle: ActionButtonStyles.Main }],
          showCloseIconButton: true,
        };
        return [
          openWebTechDialog({ config: config2, notificationType: NotificationType.Error }),
          navigateTo({ path: '/dashboard' }),
        ];
      }
    }
  };

  constructor(private readonly controlPageFacade: ControlPageFacade) {}
}
