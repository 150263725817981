import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import {
  selectEulaState,
  selectLoadingState,
  getApps,
  showOrganizationSwitcher,
  selectEulaContent,
  selectEulaConfig,
  selectApiError,
  selectCatalogInfo,
  selectVersions,
  selectSessionTimedOut,
  selectExtendSession,
  selectLogoutSession,
  selectIsUserActive,
  selectInvitationId,
  selectHelpMenuToOpen,
  selectLoggedInUserProfile,
  selectRouteState,
  getEnabledApps,
  selectShowArchiveBanner,
} from './control-page.selectors';
import {
  acceptEULA,
  extendSession,
  getEulaConfig,
  loading,
  logoutSession,
  navigateTo,
  navigateToError,
  setEulaStatus,
  setExtendSession,
  setHelpMenuToOpen,
  setInvitationIdSuccess,
  setLogoutSession,
  userLoginComplete,
} from './control-page.actions';
import {
  AppState,
  ApplicationCard,
  PreviewAppId,
  // isPreviewApp,
  selectUserNotFoundState,
} from '@ra-state';
import { UserProfile } from '@rockwell-automation-inc/service';
import { LoggerService } from '@servicesV2/logger.service';
import { AppId, NavItem } from '@rockwell-automation-inc/common-utils';
import { ApiError } from '@core/api-error';

@Injectable({
  providedIn: 'root',
})
export class ControlPageFacade {
  isLoading$ = this.store$.select(selectLoadingState);
  isEulaAccepted$ = this.store$.select(selectEulaState);
  getApps$ = this.store$.select(getApps).pipe(filter((apps) => apps !== undefined));
  getAppsMap$ = this.getApps$.pipe(
    map((apps) => {
      const appsMap = new Map<AppId | PreviewAppId, ApplicationCard>();
      apps.map((app) => appsMap.set(app.appId, app));
      return appsMap;
    }),
  );
  getEnabledApps$ = this.store$.select(getEnabledApps).pipe(filter((apps) => apps !== undefined));
  isUserNotFound$ = this.store$.select(selectUserNotFoundState);
  showOrganizationSwitcher$ = this.store$.select(showOrganizationSwitcher);
  eulaConfig$ = this.store$.select(selectEulaConfig);
  eulaContent$ = this.store$.select(selectEulaContent);
  apiError$ = this.store$.select(selectApiError);
  catalogInfo$ = this.store$.select(selectCatalogInfo);
  versionInfos$ = this.store$.select(selectVersions);
  isUserActive$ = this.store$.select(selectIsUserActive);
  sessionTimedOut$ = this.store$.select(selectSessionTimedOut);
  extendSession$ = this.store$.select(selectExtendSession);
  logoutSession$ = this.store$.select(selectLogoutSession);
  getInvitationId$ = this.store$.select(selectInvitationId);
  helpMenuToOpen$ = this.store$.select(selectHelpMenuToOpen);
  loggedInUserProfile$ = this.store$.select(selectLoggedInUserProfile);
  routeState$ = this.store$.select(selectRouteState).pipe(filter((state) => state !== undefined));
  showArchiveBanner$ = this.store$.select(selectShowArchiveBanner);
  
  constructor(
    private logger: LoggerService,
    private store$: Store<AppState>,
  ) {}

  setLoading(value: boolean): void {
    this.store$.dispatch(loading({ value }));
  }

  setEULA(value: boolean): void {
    this.store$.dispatch(setEulaStatus({ value }));
  }

  acceptEULA(returnTo?: string): void {
    this.setLoading(true);
    this.store$.dispatch(acceptEULA({ value: returnTo }));
  }

  navigateTo(path: string): void {
    this.store$.dispatch(navigateTo({ path: path }));
  }

  getEulaConfig(): void {
    this.store$.dispatch(getEulaConfig());
  }

  navigateToError(apiError: ApiError): void {
    this.store$.dispatch(navigateToError({ payload: apiError }));
  }

  setUserLoggedIn(userProfile: UserProfile): void {
    this.store$.dispatch(userLoginComplete({ payload: userProfile }));
  }

  extendSession(): void {
    this.store$.dispatch(extendSession());
  }

  logoutSession(): void {
    this.store$.dispatch(logoutSession());
  }

  setExtendSession(value: boolean): void {
    this.store$.dispatch(setExtendSession({ value }));
  }

  setLogoutSession(value: boolean): void {
    this.store$.dispatch(setLogoutSession({ value }));
  }

  setInvitationId(payload: string): void {
    this.store$.dispatch(setInvitationIdSuccess({ payload }));
  }

  setHelpMenuToOpen(value: NavItem): void {
    this.store$.dispatch(setHelpMenuToOpen({ value }));
  }
}
