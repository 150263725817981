/* eslint-disable sonarjs/no-duplicate-string */
import { APP_HUBS, Configuration, ENV_VARS, Environment } from '@app/models/config.model';
import { getEnvOrDefault } from '@app/v2/shared/utils';
import {
  ApplicationCard,
  ApplicationCardStaticConfiguration,
  PreviewAppId,
  TenantService,
  isPreviewApp,
} from '@ra-state';
import { AppId } from '@rockwell-automation-inc/common-utils';
const DESIGN_HUB_LOGO = 'Design_Hub_logo.svg';
const OPERATIONS_HUB_LOGO = 'Operations_Hub_logo.svg';
const MAINTENANCE_HUB_LOGO = 'Maintenance_Hub_logo.svg';

const isAppActive =
  (appIds: AppId[] | PreviewAppId[]) =>
  (servicesAccessibility: TenantService[]): boolean => {
    const result = appIds.map((appId) => {
      return (
        Boolean(
          servicesAccessibility?.find((service) => service.kind === appId && service.canAccess && service.provisioned),
        ) || isPreviewApp(appId)
      );
    });
    return result.includes(true);
  };

const applications: ApplicationCardStaticConfiguration[] = [
  {
    appName: 'FactoryTalk Vault',
    appHub: APP_HUBS.DESIGN_HUB,
    appDescription:
      '<span class="header-text">FactoryTalk Vault</span></b> \n Secure storage and file management for automation projects. \n <span class="header-text">FactoryTalk design Tools</span> \n Upgrade, convert, and analyze files in FactoryTalk Vault.',
    appImage: 'Vault.svg',
    hubLogo: DESIGN_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.Vault]),
    appId: AppId.Vault,
  },
  {
    appName: 'FactoryTalk Remote Access',
    appHub: APP_HUBS.OPERATIONS_HUB,
    appDescription:
      'FactoryTalk Remote Access is a web-based client that initiates a VPN connection and Remote Desktop to either the Stratix 4300 Router or Stratix RemoteAccess Runtime. FactoryTalk Remote Access provides centralized management of role-based users, groups, and devices. View audits and logs of domain activity and remote connections.',
    appImage: 'Remote_Access.svg',
    hubLogo: OPERATIONS_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.SecureRemoteAccess]),
    appId: AppId.SecureRemoteAccess,
    isDefaultTrialEnabled: true,
  },
  {
    appName: 'Fiix',
    appHub: APP_HUBS.MAINTENANCE_HUB,
    appDescription:
      'Manage maintenance on 1000s of assets with a few clicks and fewer disruptions with Fiix by Rockwell Automation',
    appImage: 'Fiix.svg',
    hubLogo: MAINTENANCE_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([PreviewAppId.Fiix]),
    appId: PreviewAppId.Fiix,
  },
  {
    appName: 'FactoryTalk Design Studio',
    appHub: APP_HUBS.DESIGN_HUB,
    appDescription:
      'FactoryTalk® Design Studio™ is a new cloud-based software product built from the ground up to improve system design efficiency. Beginning with CompactLogix and ControlLogix design, FactoryTalk Design Studio reduces development time by leveraging modern software development practices, automated multi-user collaboration tools, and support for multiple controllers in a single project.',
    appImage: 'Design_Studio.svg',
    hubLogo: DESIGN_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.IDE]),
    appId: AppId.IDE,
  },
  {
    appName: 'Foo (Integration Sample)',
    appHub: APP_HUBS.MAINTENANCE_HUB,
    appDescription: 'Sample Application to show CS integration',
    appImage: 'Foo.svg',
    hubLogo: MAINTENANCE_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.FooService]),
    appId: AppId.FooService,
    supportsUniversalCredits: true,
  },
  {
    appName: 'Plex',
    appHub: APP_HUBS.OPERATIONS_HUB,
    appDescription:
      'The Plex Smart Manufacturing Platform is a digital system of record that connects people, systems, machines, and supply chains, automates business processes, tracks data from the shop floor to the top floor, and delivers analytics for unmatched visibility, quality, and control.',
    appImage: 'Plex.svg',
    hubLogo: OPERATIONS_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([PreviewAppId.Plex]),
    appId: PreviewAppId.Plex,
  },
  {
    appName: 'FT Twin Studio',
    appHub: APP_HUBS.DESIGN_HUB,
    appDescription:
      'FactoryTalk® Twin Studio™ is an end-to-end automation design solution where users can design, program, simulate, emulate and virtually commission in one cloud environment. This cloud-hosted ecosystem enables you to work within and move seamlessly between Arena®, Studio 5000 Logix Designer®, FactoryTalk® Logix Echo and Emulate3D™ design solutions, all while collaborating with teammates in real time.',
    appImage: 'Twin_Studio.svg',
    hubLogo: DESIGN_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.Emulate3D, AppId.Arena, AppId.Logix5000, AppId.TwinStudio]),
    appId: AppId.TwinStudio,
    supportsUniversalCredits: true,
  },
  {
    appName: 'FactoryTalk Optix',
    appHub: APP_HUBS.DESIGN_HUB,
    appDescription:
      'Welcome to a new open, scalable, modular visualization platform that provides options with modern technologies, extensibility and Industry 4.0 interoperability',
    appImage: 'Optix.svg',
    hubLogo: DESIGN_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.FTOptix]),
    appId: AppId.FTOptix,
    isDefaultTrialEnabled: true,
  },
  {
    appName: 'FactoryTalk DataMosaix',
    appHub: APP_HUBS.OPERATIONS_HUB,
    appDescription:
      'FactoryTalk DataMosaix is an Industrial Data Platform that helps customers aggregate data from multiple sources and contextualize it so that it is more meaningful and provides deeper insights to their business.',
    appImage: 'Data_Mosaix.svg',
    hubLogo: OPERATIONS_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.DataMosaix]),
    appId: AppId.DataMosaix,
  },
  {
    appName: 'FactoryTalk Energy Manager',
    appHub: APP_HUBS.OPERATIONS_HUB,
    appDescription:
      'FTEM is a web-based, multi-tenant application to monitor energy usage in the manufacturing Industry. It can monitor the usage of electricity, gas, water, and other energy source through an intuitive dashboard',
    appImage: 'Energy_Manager.svg',
    hubLogo: OPERATIONS_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.EnergyManager]),
    appId: AppId.EnergyManager,
  },
  {
    appName: 'Batch Performance Analytics',
    appHub: APP_HUBS.OPERATIONS_HUB,
    appDescription:
      'Batch Performance Analytics is a web-based, multi-tenant application to monitor batch performance in the manufacturing Industry. It can monitor the batch performance through an intuitive dashboard.',
    appImage: 'Batch_PA.svg',
    hubLogo: OPERATIONS_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.BatchPerformanceAnalytics]),
    appId: AppId.BatchPerformanceAnalytics,
    featureFlagName: 'tile_bpa',
  },
  {
    appName: 'Enterprise FTAC',
    appHub: APP_HUBS.MAINTENANCE_HUB,
    appDescription:
      'Enterprise Device Manager is an integrated system that provides a periodic update of the customer’s OT device inventory, proactive notification of inventory changes, and lifecycle updates.',
    appImage: 'Asset_Centre.svg',
    poweredBy: '',
    hubLogo: 'Maintenance_Hub_logo.svg',
    isActive: false,
    isAppActive: isAppActive([AppId.AssetCenter]),
    appId: AppId.AssetCenter,
  },
  {
    appName: 'PlantPAx Analytics',
    appHub: APP_HUBS.OPERATIONS_HUB,
    appDescription:
      "PlantPAx Analytics is a Rockwell Automation solution that delivers alarm & event KPI dashboards and reports in a single platform. Combine one or multiple site's data to unlock the full value of your process data.",
    appImage: 'Plant_PAx.svg',
    hubLogo: OPERATIONS_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.PPaxAnalytics]),
    appId: AppId.PPaxAnalytics,
    featureFlagName: 'tile_ppax_analytics',
  },
  {
    appName: 'Converged Data Services',
    appHub: APP_HUBS.OPERATIONS_HUB,
    appDescription:
      'Converged Data Services are a suite of independent, multitenant, data services that provide capabilities such as data modeling, data meshing and data integration. All CDS services provide an API layer to interact with them.',
    appImage: 'Converged_Data_Services.svg',
    hubLogo: OPERATIONS_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.CDS]),
    appId: AppId.CDS,
    featureFlagName: 'tile_cds',
  },
  {
    appName: 'FactoryTalk Motion Analyzer',
    appHub: APP_HUBS.DESIGN_HUB,
    appDescription:
      'FactoryTalk® Motion Analyzer™ is a comprehensive motion-application design tool used for the analysis, optimization, selection, and validation of your motion control system. This software simplifies the machine design process, letting you quickly design and validate new machine concepts without purchasing or installing physical equipment. You can select from a wide range of Kinetix motors and drives, PowerFlex drives, and motors and gearboxes from our Technology Partners. The tool recommends solutions based on your application requirements and generates a bill of materials that can be exported into ProposalWorks for procurement.',
    appImage: 'Motion_Analyzer.svg',
    hubLogo: DESIGN_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([PreviewAppId.FactoryTalkMotionAnalyzer]),
    appId: PreviewAppId.FactoryTalkMotionAnalyzer,
    featureFlagName: 'tile_motion_analyzer',
  },
  {
    appName: 'FactoryTalk Analytics VisionAI',
    appHub: APP_HUBS.OPERATIONS_HUB,
    appDescription:
      'FactoryTalk Analytics VisionAI is an AI quality platform for manufacturing. It provides AI vision that identifies and classifies defects, delivering actionable insights used to improve quality and maximize yield',
    appImage: 'Vision_Ai.svg',
    hubLogo: OPERATIONS_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.Elementary]),
    appId: AppId.Elementary,
    featureFlagName: 'tile_elementary',
  },
  {
    appName: 'Advisor',
    appHub: APP_HUBS.DESIGN_HUB,
    appDescription: 'Product Selection & Configuration',
    appImage: 'Advisor.svg',
    hubLogo: DESIGN_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([PreviewAppId.Advisor]),
    appId: PreviewAppId.Advisor,
    featureFlagName: 'tile_advisor',
  },
  {
    appName: 'FactoryTalk Enterprise Performance Manager',
    appHub: APP_HUBS.OPERATIONS_HUB,
    appDescription: 'FactoryTalk Metrics Enterprise Performance Manager',
    appImage: 'FT_Metrics_EPM.svg',
    hubLogo: OPERATIONS_HUB_LOGO,
    isActive: false,
    isAppActive: isAppActive([AppId.MEPM]),
    appId: AppId.MEPM,
    featureFlagName: 'tile_mepm',
  },
];
const applicationMap = Object.fromEntries(applications.map((item) => [item.appId.toString(), item]));

export function createEnvConfig(config: Partial<Configuration>, enabledApps: any): Environment {
  const applications = enabledApps.map((app) => Object.assign(applicationMap[app.appId], app)) as ApplicationCard[];
  const names = applications.reduce((map, app) => {
    map[app.appId] = app;
    return map;
  }, {});
  // env overrides from index-html-transform.local.ts
  config.notificationsBaseUrl = getEnvOrDefault(ENV_VARS.CS_NOTIFICATION_API_BASE_URL, config.notificationsBaseUrl);
  config.csApiBaseUrl = getEnvOrDefault(ENV_VARS.CS_API_BASE_URL, config.csApiBaseUrl);
  config.csPortalUrl = getEnvOrDefault(ENV_VARS.CS_PORTAL_URL, config.csPortalUrl);

  if (config.authConfig && !config.authConfig.redirectUri) {
    config.authConfig.redirectUri = `${config.csPortalUrl}/callback`;
  }
  const appConfig = {
    ...config,
    get applications(): ApplicationCard[] {
      return applications;
    },
    appName(id: string): string {
      return (names[id] && names[id].appName) || id;
    },

    isApiCall(url: string): boolean {
      try {
        const parsedUrl = new URL(url);
        return parsedUrl.href.startsWith(`${config.csApiBaseUrl}/api/`);
      } catch (ex) {
        return url.startsWith('/api/');
      }
    },
  } as Configuration;

  return {
    production: appConfig.production || false,
    appConfiguration: appConfig,
  };
}
